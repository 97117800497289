@import "../styles/common/global.scss";

.throbber {
  animation-name: throbber-rotation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes throbber-rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.throbber__track {
  fill-opacity: 0;
  stroke: $gray-300;
  stroke-width: 0.1em;
}

.throbber__worm {
  fill-opacity: 0;
  stroke: $primary;
  stroke-width: 0.1em;
  stroke-linecap: round;
}
