@import "./variables.scss";
@import "~bootstrap/scss/bootstrap.scss";

@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}

@include bg-variant(".bg-black", black, true);

@import "./hacks.scss";
