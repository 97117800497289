
.shift-hours,
.timesheet-total-hours {
  min-width: 7.5em;
}

.shift-input {
  label {
    user-select: none;
  }
}
